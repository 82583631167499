import { useState, useEffect } from 'react'

import mixpanel from 'mixpanel-browser'
import clsx from 'clsx'
import { useRouter } from 'next/router'
import useSWR from 'swr'
import { FormattedMessage } from 'react-intl'

import { Navbar, Body, Close } from 'components/page/drawer'
import * as Components from 'layouts/products/forecast'
import Shim from 'components/Shim'
import Sponsor from 'layouts/sponsor'
import { Details } from 'components/error'
import * as Async from 'contexts/async'
import typography from 'components/text/Text.module.css'
import shim from 'components/Shim.module.css'
import { ExpandButton } from 'layouts/products/forecast/ExpandButton'
import { Static } from 'prismic/layouts'
import * as Page from '@avcan/prismic/clients/page'
import { DateElement } from 'components/time'
import { Info } from 'components/icons/Info'
import { FORECAST_OPENED } from '@avcan/constants/products/mixpanel'

export default function ForecastDrawer({ id, close, isArchive = false }) {
    const { locale } = useRouter()
    const swr = useSWR([id, locale], Components.get)

    useEffect(() => {
        if (id) {
            mixpanel.track(FORECAST_OPENED, {
                id,
            })
        }
    }, [id])

    return (
        <Async.Provider value={swr}>
            <Navbar>
                <Async.Found>
                    {isArchive ? <ArchivedNotice /> : <Sponsor label={null} />}
                </Async.Found>
                <Close onClick={close} />
            </Navbar>
            <Body key={id}>
                <Async.Pending>
                    <p className={clsx(typography.Muted, shim.all)}>
                        <FormattedMessage
                            description="Layout drawers/Forecast"
                            defaultMessage="Loading avalanche forecast..."
                        />
                    </p>
                </Async.Pending>
                <Async.Found>
                    <ForecastContent />
                </Async.Found>
                <Async.FirstError>
                    <Async.NotFound>
                        <Shim horizontal>
                            <FlexibleForecastFAQ />
                        </Shim>
                    </Async.NotFound>
                    <Async.Error>
                        <Details
                            className={shim.all}
                            summary={
                                <FormattedMessage
                                    defaultMessage="An error happened while loading the forecast."
                                    description="Layout drawers/Forecast"
                                />
                            }
                        />
                    </Async.Error>
                </Async.FirstError>
            </Body>
        </Async.Provider>
    )
}

// Utils and Constants
const ArchivedNotice = ({ payload }) => {
    return (
        <div style={style.archivedNotice}>
            <Info />
            <div>
                <FormattedMessage
                    defaultMessage="Archived forecast for {date}"
                    values={{
                        date: <DateElement value={payload.product?.report?.validUntil} />,
                    }}
                    description="Forecast details pane"
                />
            </div>
        </div>
    )
}
const style = {
    archivedNotice: {
        display: 'flex',
        alignItems: 'center',
        gap: '0.5em',
        marginRight: '1.5em',
        marginLeft: '0.5em',
        background: 'var(--gray-lighter)',
        borderRadius: '4px',
        padding: '0.5em 1em',
    },
}

// TODO Reuse that piece of code...
function ForecastContent({ payload }) {
    const { id } = payload.product

    return (
        <Components.Provider {...payload}>
            <Shim horizontal>
                <Components.Metadata />
                <ExpandButton id={id} />
                <Components.Notifications />
                <Components.Headline />
            </Shim>
            <Components.TabSet />
            <Components.Footer />
        </Components.Provider>
    )
}

// This used to be a display of the regions that you could click on to see their forecast,
// but now we're following the same pattern as the /forecasts page, which is to display
// the Flexible Forecast FAQs.
function FlexibleForecastFAQ() {
    const [document, setDocument] = useState(null)
    const { locale } = useRouter()

    useEffect(() => {
        async function getDocument() {
            const document = await Page.get('forecast-faq', locale)
            const documentWithoutSidebar = {
                ...document,
                data: {
                    ...document.data,
                    sidebar: [],
                    sharing: 'No',
                    following: 'No',
                    contacting: 'No',
                },
            }
            setDocument(documentWithoutSidebar)
        }
        getDocument()
    }, [])

    return <>{document && <Static document={document} />}</>
}
