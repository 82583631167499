import { Client, Interceptors } from '../../fetch'
import * as URLUtils from '../../lib/url'

export default class GeocoderClient {
    constructor(base, key) {
        const url = URLUtils.buildPath(base, 'v1', 'search')

        this.client = new Client(url, new Interceptors.APIKey(key))
    }
    search(term) {
        return this.client.get(term)
    }
}
