import { useEffect } from 'react'

import Link from 'next/link'
import { FormattedMessage } from 'react-intl'
import mixpanel from 'mixpanel-browser'

import * as Drawer from 'components/page/drawer'
import * as Components from 'layouts/products/min'
import * as Texts from 'components/text'
import * as Async from 'contexts/async'
import * as MINClient from 'clients/min'
import Sponsor from 'layouts/sponsor'
import Shim from 'components/Shim'
import { submission } from 'utils/min'
import { useFlyTo, useSecondaryDrawer } from 'layouts/map/drawers/hooks'
import { NameMessages } from 'constants/products/names'
import { MOUNTAIN_INFORMATION_NETWORK } from '@avcan/constants/products'
import { MIN_REPORT_OPENED } from '@avcan/constants/products/mixpanel'

export default function MountainInformationNetwork() {
    const { id, close } = useSecondaryDrawer()
    const subject = <FormattedMessage {...NameMessages[MOUNTAIN_INFORMATION_NETWORK]} />

    useEffect(() => {
        if (id) {
            mixpanel.track(MIN_REPORT_OPENED, {
                id,
            })
        }
    }, [id])

    return (
        <Async.Provider value={MINClient.useSubmission(id)}>
            <Drawer.Navbar>
                <Sponsor label={null} />
                <Drawer.Close onClick={close} />
            </Drawer.Navbar>
            <Drawer.Header subject={subject}>
                <Async.Pending>
                    <Texts.Loading as="h1" />
                </Async.Pending>
                <Async.Found>
                    <ReportTitle />
                </Async.Found>
                <Async.NotFound>
                    <Texts.Warning as="h1">
                        <FormattedMessage
                            description="Layout drawers/MountainInformationNetwork"
                            defaultMessage="Report not found."
                        />
                    </Texts.Warning>
                </Async.NotFound>
            </Drawer.Header>
            <Drawer.Body>
                <Shim horizontal>
                    <Async.Pending>
                        <Texts.Loading>
                            <FormattedMessage
                                description="Layout drawers/MountainInformationNetwork"
                                defaultMessage="Loading Mountain Information Network report..."
                            />
                        </Texts.Loading>
                    </Async.Pending>
                    <Async.NotFound>
                        <p>
                            <FormattedMessage
                                description="Layout drawers/MountainInformationNetwork"
                                defaultMessage="Report with id {id} has not been found."
                                values={{ id }}
                            />
                        </p>
                    </Async.NotFound>
                </Shim>
                <Async.Found>
                    <Content />
                </Async.Found>
            </Drawer.Body>
        </Async.Provider>
    )
}

// Utils
function Content({ payload }) {
    const { displaySchemaId } = payload.schemaVersions
    const schema = MINClient.useDisplaySchema(displaySchemaId)

    return (
        <Async.Provider value={schema}>
            <Async.Found>
                <Components.Provider data={payload} schema={schema.data}>
                    <Shim horizontal>
                        <Components.Metadata />
                    </Shim>
                    <Shim vertical>
                        <Components.ObservationsSet />
                    </Shim>
                    <Components.Gallery />
                </Components.Provider>
            </Async.Found>
        </Async.Provider>
    )
}
function ReportTitle({ payload }) {
    const { submissionID, id = submissionID, title, location } = payload
    const flyTo = useFlyTo()
    function handleLocationClick() {
        const { longitude, latitude } = location

        flyTo([longitude, latitude])
    }

    return (
        <h1>
            <Link href={submission(id)}>{title}</Link>
            <Drawer.DisplayOnMap onClick={handleLocationClick} />
        </h1>
    )
}
