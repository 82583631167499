import { useEffect } from 'react'

import Link from 'next/link'
import { useRouter } from 'next/router'
import mixpanel from 'mixpanel-browser'

import { List, ListItem } from 'components/page'
import { Header, Body, Navbar, Close, DisplayOnMap } from 'components/page/drawer'
import { Metadata, Station, Footer } from 'components/weather/station'
import { Loading, Warning } from 'components/text'
import Shim from 'components/Shim'
import * as Async from 'contexts/async'
import * as WeatherClient from 'clients/weather'
import Sponsor from 'layouts/sponsor'
import * as utils from 'utils/station'
import { FormattedMessage } from 'react-intl'
import { useFlyTo, useSecondaryDrawer } from 'layouts/map/drawers/hooks'
import { NameMessages } from 'constants/products/names'
import { WEATHER_STATION } from '@avcan/constants/products'
import { createPath } from 'utils/product'
import * as URLUtils from '@avcan/utils/url'
import { WEATHER_STATION_OPENED } from '@avcan/constants/products/mixpanel'

export default function WeatherStation() {
    const { close, id } = useSecondaryDrawer()
    const subject = <FormattedMessage {...NameMessages[WEATHER_STATION]} />

    useEffect(() => {
        if (id) {
            mixpanel.track(WEATHER_STATION_OPENED, {
                id,
            })
        }
    }, [id])

    return (
        <Async.Provider value={WeatherClient.useStation(id)}>
            <Navbar>
                <Sponsor label={null} />
                <Close onClick={close} />
            </Navbar>
            <Header subject={subject}>
                <h1>
                    <Async.Pending>
                        <Loading as="span" />
                    </Async.Pending>
                    <Async.Found>
                        <Heading />
                    </Async.Found>
                    <Async.NotFound>
                        <Warning as="span">
                            <FormattedMessage
                                description="Layout drawers/WeatherStation"
                                defaultMessage="Weather station #{id} not found"
                                values={{ id }}
                            />
                        </Warning>
                    </Async.NotFound>
                </h1>
            </Header>
            <Body>
                <Async.Pending>
                    <Shim horizontal>
                        <Loading />
                    </Shim>
                </Async.Pending>
                <Async.Found>
                    {station => (
                        <>
                            <Shim horizontal>
                                <Metadata {...station} />
                            </Shim>
                            <Measurements id={id} />
                            <Shim horizontal>
                                <Footer />
                            </Shim>
                        </>
                    )}
                </Async.Found>
                <Async.NotFound>
                    <StationList />
                </Async.NotFound>
            </Body>
        </Async.Provider>
    )
}

// Utils
function Measurements({ id }) {
    return (
        <Async.Provider value={WeatherClient.useMeasurements(id)}>
            <Async.Pending>
                <Loading>
                    <FormattedMessage
                        description="Layout drawers/WeatherStation"
                        defaultMessage="Loading weather station measurements..."
                    />
                </Loading>
            </Async.Pending>
            <Async.Found>{measurements => <Station measurements={measurements} />}</Async.Found>
        </Async.Provider>
    )
}

function StationList() {
    const { query } = useRouter()

    return (
        <Async.Provider value={WeatherClient.useStations()}>
            <h3>
                <FormattedMessage
                    description="Layout drawers/WeatherStation"
                    defaultMessage="Click on a link below to see another weather station:"
                />
            </h3>
            <Async.Found>
                {stations => (
                    <List column={1}>
                        {stations.map(({ stationId, name }) => {
                            const url = {
                                pathname: URLUtils.buildPath('/map', query.path),
                                query: {
                                    panel: createPath(WEATHER_STATION, stationId),
                                },
                            }

                            return (
                                <ListItem key={stationId}>
                                    <Link href={url} replace>
                                        {name}
                                    </Link>
                                </ListItem>
                            )
                        })}
                    </List>
                )}
            </Async.Found>
        </Async.Provider>
    )
}

function Heading({ payload }) {
    const { stationId, name, longitude, latitude } = payload
    const flyTo = useFlyTo()
    function handleLocateClick() {
        flyTo([longitude, latitude])
    }

    return (
        <>
            <Link href={utils.path(stationId)}>{name}</Link>
            <DisplayOnMap onClick={handleLocateClick} />
        </>
    )
}
